(function ($, document) {
    "use strict";

    $(document).ready(function () {
        $('article .main .ce_youtube, article .main .ce_vimeo, article .main .ce_image, article .main .ce_oembed_podigee').each(function(i, el) {
            var _el = $(el);
            var height = _el.parents('.main').prev('.meta').children('.inside').height();

            if (height !== null && _el.position().top < height) {
                _el.css('margin-left', 0);
            }
        });
    });

    window.algoliaPagination = function (current, total) {
        if (total < 1) {
            return '';
        }

        var buffer = '<div class="pagination block"><p>Seite ' + (current + 1) + ' von ' + total + '</p>';

        if (total > 1) {
            var url = window.location.href.split('#')[0];
            buffer = buffer + '<ul>';

            if (current > 1) {
                buffer = buffer + '<li class="first"><a href="' + url + '#search-form" onclick="return algoliaSearch(0)" class="first" title="Gehe zu Seite 1">« Anfang</a></li>'
            }

            if (current > 0) {
                buffer = buffer + '<li class="previous"><a href="' + url + '#search-form" onclick="return algoliaSearch(' + (current-1) + ')" class="previous" title="Gehe zu Seite ' + current + '">Zurück</a></li>'
            }

            var low  = total-7;
            var high = current+4;

            for (var start = (current-3 > low ? low : (current-3)); start<current; start++) {
                if (start < 0) {
                    high++;
                    continue;
                }

                buffer = buffer + '<li><a href="' + url + '#search-form" onclick="return algoliaSearch(' + start + ')" class="link" title="Gehe zu Seite ' + (start+1) + '">' + (start+1) + '</a></li>';
            }

            buffer = buffer + '<li><span class="active">' + (current+1) + '</span></li>';

            for (var stop = current+1; stop<high; stop++) {
                if (stop >= total) break;

                buffer = buffer + '<li><a href="' + url + '#search-form" onclick="return algoliaSearch(' + stop + ')" class="link" title="Gehe zu Seite ' + (stop+1) + '">' + (stop+1) + '</a></li>';
            }

            if (current < (total-1)) {
                buffer = buffer + '<li class="next"><a href="' + url + '#search-form" onclick="return algoliaSearch(' + (current+1) + ')" class="next" title="Gehe zu Seite ' + (current+2) + '">Vorwärts</a></li>'
            }

            if (current < (total-2)) {
                buffer = buffer + '<li class="last"><a href="' + url + '#search-form" onclick="return algoliaSearch(' + (total-1) + ')" class="last" title="Gehe zu Seite ' + (total) + '">Ende »</a></li>'
            }

            buffer = buffer + '</ul>';
        }

        buffer = buffer + '</div>';

        return buffer;
    }
})(jQuery, document);
