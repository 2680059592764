jQuery(document).ready(function($) {
    "use strict";

    var modules = $('.mod_news_overview');

    if (!modules.length) return;

    modules.each(function() {

        var nav, list, config,
            view = 'latest',
            $container = $(this),
            $latest = $container.find('.latest'),
            $most_read = $container.find('.most_read').hide().fadeOut();

        $container.find('.views').css('display', 'flex').find('input[type=radio]').change(function() {
            var target = $(this).val();
            if (view === target) return;

            lockHeight();

            $(this).parent().addClass('active').siblings().removeClass('active');

            if ('most_read' === target && !config) {
                initMostRead();
            } else {
                unlockAnimate();
            }

            view = target;
        });

        // Show most read on homepage by default
        if (document.querySelector('body.layout-homepage')) {
            const radio = $container[0].querySelector('.views input[type=radio][value=most_read]');
            radio.checked = true;
            radio.parentNode.classList.add('active');
            $container.find('.views input[type=radio][value=most_read]').parent().siblings().removeClass('active');
            initMostRead();
            view = 'most_read';
        }

        function initMostRead() {
            config = $.parseJSON($most_read.attr('data-config'));
            nav  = $('<fieldset class="radio_container period"></fieldset>');

            $most_read.empty().append(nav);

            $.each(config, function (i, row) {
                nav.append(
                    $('<span><input type="radio" name="most_read" id="ctrl_most_read_'+i+'"> <label for="ctrl_most_read_'+i+'">' + row.label + '</label></span>').change(function() {
                        $(this).addClass('active').siblings().removeClass('active');
                        fetch(row.url);
                    })
                )
            });

            nav.children().first().addClass('active').find('input').attr('checked', true);
            fetch(config[0].url, unlockAnimate);
        }

        function fetch(url, complete) {
            lockHeight();

            $.getJSON(url, '', function (data) {
                list && list.remove();

                if (!$.isArray(data) || 0 === data.length) {
                    if (document.documentElement.lang === 'en') {
                        list = $('<p class="message empty">No articles found.</p>');
                    } else {
                        list = $('<p class="message empty">Keine Artikel gefunden.</p>');
                    }
                } else {
                    list = $('<ol></ol>');

                    $.each(data, function (i, item) {
                        list.append($('<li>' + item + '</li>'));
                    });
                }

                $most_read.prepend(list);
                complete ? complete() : unlockHeight();

            }).fail(function () {
                list && list.remove();
                list = $('<p class="message error">Fehler bei der Abfrage.</p>');
                $most_read.prepend(list);
                complete ? complete() : unlockHeight();
            });
        }

        function lockHeight() {
            $container.css('height', $container.height()+3).addClass('loading');
            $latest.add($most_read).css('position', 'absolute');
        }

        function unlockHeight() {
            $latest.add($most_read).css('position', 'relative');
            $container.removeClass('loading').css('height', 'auto');
        }

        function unlockAnimate() {
            var $clone = $container.clone();

            $clone.css(
                {
                    width: $container.width(),
                    height:'auto',
                    position: 'absolute',
                    left: '-999em'
                }
            ).appendTo('body').find('.list_items').css('position', 'relative').fadeToggle(0);

            $container.removeClass('loading').animate(
                {
                    height: $clone.height()+3
                },
                {
                    complete: function() {
                        $latest.add($most_read).css('position', 'relative');
                        $container.css('height', 'auto');
                    }
                }
            );

            $latest.add($most_read).fadeToggle();

            $clone.remove();
        }
    });
});
