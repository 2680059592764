import '../styles/app.scss';

require('./_standalone.js');
require('./_jump_to_headline');
require('./_menu.js');
require('./_news_overview.js');
require('./_video.js');
require('./_cookiebar.js');
require('./_forms.js');
require('./_misc.js');
require('./_ads.js');
require('./_images.js');
