window.AdServer = {
    register: function(zone, element, keywords) {
        var el = document.getElementById(element);

        if (!el) {
            return;
        }

        jQuery.getJSON(
            'https://servedbyadbutler.com/adserve/;ID=166845;setID='+zone+';type=json;kw='+keywords,
            null,
            function(data) {
                if (data.status !== 'SUCCESS' || !data.placements || !data.placements.placement_1) {
                    $(el).addClass('ad_empty');
                    return;
                }

                var config = data.placements.placement_1;

                if (config.body) {

                    var contentDocument, wrapperFrame;
                    wrapperFrame = document.createElement("iframe");
                    wrapperFrame.id = 'placement_' + element + '_iframe';
                    wrapperFrame.frameBorder = 0;
                    wrapperFrame.scrolling = "no";
                    wrapperFrame.noresize = "noresize";
                    wrapperFrame.marginheight = 0;
                    wrapperFrame.marginwidth = 0;
                    wrapperFrame.height = config.height;
                    wrapperFrame.width = config.width;

                    $(el).addClass('ad_loaded').empty().append($('<div/>').append(wrapperFrame));

                    contentDocument = wrapperFrame.contentDocument;
                    contentDocument.open();
                    contentDocument.write("<!DOCTYPE HTML><html><head><style>html,body{padding:0;margin:0;}</style></head><body>" + config.body + "</body></html>");
                    contentDocument.close();

                } else if (config.image_url) {
                    $(el).addClass('ad_loaded').empty().append(
                        $('<div/>').append(
                            $('<a/>', {
                                href: config.redirect_url,
                                target: config.target,
                                rel: 'nofollow'
                            }).append($('<img src="'+config.image_url+'" width="'+config.width+'" height="'+config.height+'" alt="'+config.alt_text+'">'))
                        )
                    )
                }
            }
        );
    }
};
