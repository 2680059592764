window.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.ce_jump_to_headline');

    if (elements) {
        elements.forEach((el) => {
            const level = el.getAttribute('data-level') || 'h3';
            const headline = el.getAttribute('data-headline');

            if (headline) {
                const title = document.createElement('p');
                title.innerHTML = headline;
                el.appendChild(title);
            }

            const targets = el.parentNode.querySelectorAll(level);
            const select = document.createElement('select');

            const option = document.createElement('option');
            option.value = '';
            option.innerHTML = 'Land wählen …';
            select.appendChild(option);

            select.addEventListener('change', (event) => {
                if (select.value !== '' && targets[select.value]) {
                    targets[select.value].scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            });

            targets.forEach((target, i) => {
                const option = document.createElement('option');
                option.value = String(i);
                option.innerHTML = target.innerText;
                select.appendChild(option);
            });

            el.appendChild(select);
        });
    }
});
