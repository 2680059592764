
window.addEventListener('DOMContentLoaded', () => {
    const error = document.querySelector('.formbody .error');

    if (!error) {
        return;
    }

    error.scrollIntoView({
        behavior: 'smooth'
    });
});
