(function($, _win, _doc) {
    "use strict";

    $(_doc).ready(function() {
        var body    = $('body');
        var active  = false;
        var actions = {
            mobile: {
                button: $('#mobile-toggle')
            },
            search: {
                button: $('#search-toggle'),
                onShow: function() {
                    $('#search-rollover').find('input.text').focus();
                }
            }
        };

        function rollover(id, config) {
            var visible = false;

            if (config.content) {
                config.button.hover(show, hide);
                config.content.hover(show, hide);
            } else {
                config.button.click(toggle);
            }

            function show(e) {
                if (e) e.preventDefault();

                if (active !== false && active !== id) {
                    actions[active].hideNow();
                }

                visible = true;
                active = id;
                body.addClass(id + '-active');

                if (config.onShow) {
                    config.onShow();
                }
            }

            function hide(e) {
                if (e) e.preventDefault();

                visible = false;

                setTimeout(function() {
                    if (!visible) {
                        active = false;
                        body.removeClass(id + '-active');
                    }
                }, 100);
            }

            function hideNow() {
                visible = false;
                body.removeClass(id + '-active');
            }

            function toggle(e) {
                if (visible === false) {
                    show(e);
                } else {
                    hide(e);
                }
            }

            return {
                show: show,
                hide: hide,
                hideNow: hideNow
            }
        }

        for (var key in actions) {
            if (actions.hasOwnProperty(key)) {
                $.extend(actions[key], rollover(key, actions[key]));
            }
        }
    });
})(jQuery, window, document);
