window.addEventListener('DOMContentLoaded', () => {
    if (window.localStorage.getItem('cookiebar') === '1') {
        return;
    }

    const cookiebar = document.createElement('div');
    cookiebar.classList.add('cookiebar');

    if (document.documentElement.lang === 'en') {
        cookiebar.innerHTML = '<div class="inside"><button><span class="mobile-only">OK</span><span class="desktop-only">Get \'em cookies</span></button><p>Like almost all websites on the web, Travelnews uses cookies to improve the user experience on our site. <span class="desktop-only">Cookies are small data files that are stored on your computer.</span> If you continue without changing your settings, we will take this as your consent to accept cookies from us. <a href="cookies.html" target="_blank">Learn more</a></p></div>';
    } else {

        cookiebar.innerHTML = '<div class="inside"><button><span class="mobile-only">OK</span><span class="desktop-only">Her mit den Cookies!</span></button><p>Wie fast alle Websites im Netz nutzt auch Travelnews Cookies, um die Nutzer&shy;erfahrung auf unserer Seite zu verbessern. <span class="desktop-only">Cookies sind kleine Datenfiles, welche auf Ihrem Computer abgelegt werden.</span> Wenn Sie weiter&shy;fahren, ohne Ihre Einstellungen zu ändern, nehmen wir das als Ihr Einverständnis, Cookies von uns zu akzeptieren. <a href="cookies.html" target="_blank">Mehr&nbsp;erfahren</a></p></div>';
    }

    const button = cookiebar.querySelector('button');
    button.addEventListener('click', () => {
        window.localStorage.setItem('cookiebar', '1');
        cookiebar.remove();
    });

    document.body.append(cookiebar);
});
