window.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.video-preview').forEach((el) => {
        if (!('src' in el.dataset)) return;

        el.addEventListener('click', function (e) {
            e.preventDefault();

            el.querySelectorAll(':not(figcaption)').forEach((child) => {
                child.remove()
            });

            // 800x450 = 16:9
            const video = document.createElement('div');
            video.classList.add('responsive-video');
            video.innerHTML = `<iframe src="${el.dataset.src}" type="text/html" width="800" height="450" allowfullscreen></iframe>`;
            el.prepend(video);

            el.classList.remove('video-preview');
        }, { once: true });
    })
});
